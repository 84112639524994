import React, { useContext } from "react";
import { GET_COMPANY_BY_EXTERNAL_UUID } from "~/graphql/queries/CompanyQueries";
import { useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router-dom";
import {
  Text,
  Paper,
  Tabs,
  Image,
  Space,
  Anchor,
  Group,
  ActionIcon,
  Flex,
} from "@mantine/core";
import classes from "./PressKit.module.scss";
import AboutCompany from "./AboutCompany/AboutCompany";
import Images from "./Images/Images";
import Documents from "./Documents/Documents";
import Video from "./Video/Video";
import Services from "./Services/Services";
import Products from "./Products/Products";
import Team from "./Team/Team";
import Contacts from "./Contacts/Contacts";
import PressReleases from "./PressReleases/PressReleases";
import NotFoundTitle from "../Shared/NotFoundTitle";
import ShortInformation from "@/components/Companies/SordInformation/ShortInformation";
import { useTranslation } from "react-i18next";
import { CurrentUser } from "@/contexts/CurrentUserContext";
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedin,
  IconBrandTwitter,
} from "@tabler/icons-react";
import Subscribe from "./Subscribe/Subscribe";

export default function PressKit({}) {
  const currentUser = useContext(CurrentUser);
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const { presskit_uuid } = useParams();
  const { tab } = useParams();
  const { loading, error, data } = useQuery(GET_COMPANY_BY_EXTERNAL_UUID, {
    variables: { externalUuid: presskit_uuid },
  });

  if (loading) return <p>{t("loading")}</p>;
  if (error) return <NotFoundTitle />;
  const company = data.companyByExternalUuid;

  return (
    <>
      {currentUser?.user_type === "journalist" && (
        <Anchor underline={false} onClick={() => navigate(`/presskits`)}>
          {`← Back to all press kits`}
        </Anchor>
      )}
      <Space h="md" />
      <Tabs
        defaultValue={tab || "about"}
        onTabChange={(value) =>
          navigate(`/presskits/${presskit_uuid}/${value}`)
        }
      >
        <Paper shadow="xs" radius="md" p="xl" className={classes.topContainer}>
          <Flex justify="space-between" align="flex-start">
            <Image
              src={company.logoUrl}
              alt={company.name}
              width={250}
              className={classes.logo}
            />
            <Flex direction="column" justify="center" gap="sm" align="center">
              <Group>
                <ActionIcon component="a" href={company.facebook} target="_blank">
                  <IconBrandFacebook />
                </ActionIcon>
                <ActionIcon component="a" href={company.linkedin} target="_blank">
                  <IconBrandLinkedin />
                </ActionIcon>
                <ActionIcon component="a" href={company.twitter} target="_blank">
                  <IconBrandTwitter />
                </ActionIcon>
                <ActionIcon
                  component="a"
                  href={company.instagram}
                  target="_blank"
                >
                  <IconBrandInstagram />
                </ActionIcon>
              </Group>
              <Group>
                {(currentUser.user_type === 'journalist') && <Subscribe companyId={company.id} />}
              </Group>
            </Flex>
          </Flex>
          <h1 className={classes.companyName}>{company.name}</h1>
          <Anchor href={company.webUrl} target="_blank">
            {company.webUrl}
          </Anchor>

          <Text>{company.tagline}</Text>
          <ShortInformation
            tags={company.tags}
            officeAddress={company.officeAddress}
            employeesCount={company.employeesCount}
            businessType={company.businessType}
          />
          <Space h="md" />
          <Tabs.List className={classes.presskitTabs}>
            <Tabs.Tab value="about">{t("press_kits.tabs.about")}</Tabs.Tab>
            <Tabs.Tab value="team">{t("press_kits.tabs.team")}</Tabs.Tab>
            <Tabs.Tab value="press-releases">
              {t("press_kits.tabs.press_releases")}
            </Tabs.Tab>
            <Tabs.Tab value="images">{t("press_kits.tabs.images")}</Tabs.Tab>
            <Tabs.Tab value="video">{t("press_kits.tabs.videos")}</Tabs.Tab>
            <Tabs.Tab value="documents">
              {t("press_kits.tabs.documents")}
            </Tabs.Tab>
            <Tabs.Tab value="services">
              {t("press_kits.tabs.services")}
            </Tabs.Tab>
            <Tabs.Tab value="products">
              {t("press_kits.tabs.products")}
            </Tabs.Tab>
            <Tabs.Tab value="contacts">
              {t("press_kits.tabs.contacts")}
            </Tabs.Tab>
          </Tabs.List>
        </Paper>
        <Tabs.Panel value="about" pt="xs" className={classes.tabPanel}>
          <AboutCompany company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="team" pt="xs" className={classes.tabPanel}>
          <Team companyUuid={presskit_uuid} />
        </Tabs.Panel>
        <Tabs.Panel value="press-releases" pt="xs">
          <PressReleases company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="images" pt="xs">
          <Images company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="video" pt="xs">
          <Video company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="documents" pt="xs">
          <Documents company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="services" pt="xs">
          <Services company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="products" pt="xs">
          <Products company={company} />
        </Tabs.Panel>
        <Tabs.Panel value="contacts" pt="xs">
          <Contacts companyUuid={presskit_uuid} />
        </Tabs.Panel>
      </Tabs>
    </>
  );
}
